import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './modules/home/home.component';
import {PrivacidadeComponent} from './modules/privacidade/privacidade.component';


export const APP_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'privacidade',
    component: PrivacidadeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {enableTracing: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

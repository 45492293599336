import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {CardModule} from 'primeng/card';
import {GalleriaModule} from 'primeng/galleria';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './modules/home/home.component';
import {PrivacidadeComponent} from './modules/privacidade/privacidade.component';
import {FooterComponent} from './shared/components/footer/footer.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CardModule,
    GalleriaModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacidadeComponent,
    FooterComponent,
  ],
  exports: [FooterComponent],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}


import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  images: any|undefined;

  ngOnInit(): void {
    this.images = [
      {itemImageSrc: 'assets/images/galeria/1.png'},
      {itemImageSrc: 'assets/images/galeria/2.png'},
      {itemImageSrc: 'assets/images/galeria/3.png'},
      {itemImageSrc: 'assets/images/galeria/4.png'},
      {itemImageSrc: 'assets/images/galeria/5.png'},
    ]
  }
}
